.insight-badge-holder {
  position: relative;
  height: 80px;
  overflow: hidden;
  width: 100%;
  max-width: 300px;
}
.insight-badge {
  background-image: url('/img/Badges/badge-clear.png');
  height: 70px;
  width: 70px;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}
.insight-badge i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.insight-badge-text {
  transition: all 0.5s ease;
  position: absolute;
  left: 35px;
  top: 50%;
  height: 50px;
  transform: translateY(-50%);
  width: 0;
  padding-left: 40px;
  color: white;
  z-index: 50;
  border-radius: 10px;
  overflow: hidden;
}
.insight-badge-text h4 {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.text-insight-orange .insight-badge-text {
  background-color: #bd4a26;
}
.text-insight-green .insight-badge-text,
.text-insight-blue .insight-badge-text {
  background-color: #006838;
}
.insight-badge-active .insight-badge-text {
  width: 90%;
  max-width: 240px;
}
.insight-badge h5 {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translateY(-50%) translate(-50%);
  font-size: 2.2em;
}
.text-insight-orange .insight-badge h5,
.text-insight-green .insight-badge h5 {
  color: white;
}
.text-insight-blue .insight-badge h5 {
  color: black;
}
.text-insight-green .insight-badge {
  background-image: url('/img/Badges/badge-green.png');
}
.text-insight-blue .insight-badge {
  background-image: url('/img/Badges/badge-clear.png');
}
.text-insight-orange .insight-badge {
  background-image: url('/img/Badges/badge-orange.png');
}
.insight-badge-mini {
  background-image: url('/img/Badges/badge-green.png');
  height: 35px;
  width: 35px;
  background-size: cover;
  position: relative;
  float: right;
}
.insight-badge-mini h5 {
  color: inherit;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translateY(-50%) translate(-50%);
  font-size: 1em;
  color: white;
}
