.delete-button {
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 150;
  color: white;
}
.delete-button-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
