.contentSchedule {
  margin: 0 auto;
  padding: 5px 30px 1px;
  max-width: 100%;
  padding-bottom: 5px;
}
.timeLableWarning {
  background-color: #f3b760;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  /* font-weight: bold; */
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.no-bullets {
  list-style-type: none;
  padding-left: 0;
}
.no-bullets li {
  margin-bottom: 5px;
  /* Add some spacing between items */
}
