@keyframes flashHighlight {
  0%,
  100% {
    border-color: transparent;
  }
  50% {
    border-color: yellow;
  }
}
.flash-highlight {
  border: 2px solid transparent;
  /* Initial border */
  animation: flashHighlight 1s ease-in-out 3;
  /* Animation duration and repeat */
}
