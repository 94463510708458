.carrierItem {
  display: inline-block;
  width: 40px;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
}
.bannerOptions {
  padding-left: 200px;
}
