#sidebar {
  background-image: url('/img/Logo/FlightInsight-DarkerTransparent.png');
  background-repeat: no-repeat;
  background-position: 65% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}
