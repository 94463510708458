[ui-view].ng-enter,
[ui-view].ng-leave {
  -webkit-transition: opacity ease-in-out 200ms;
  transition: opacity ease-in-out 200ms;
}
[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0;
}
[ui-view].ng-enter.ng-enter-active {
  opacity: 1;
}
.Page.ng-leave.ng-leave-active {
  display: none;
}
@keyframes popIn {
  from {
    opacity: 0;
    transform: scale(0, 0);
  }
  50% {
    opacity: .7;
    transform: scale(1.5, 1.5);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes popOut {
  from {
    opacity: 1;
    transform: scale(1, 1);
  }
  50% {
    opacity: .7;
    transform: scale(0.4, 0.4);
  }
  to {
    opacity: 0;
    transform: scale(0, 0);
  }
}
.fadeInPop {
  transform: scale(1, 1);
  animation: popIn 0.5s linear;
}
.fadeInPop.ng-hide-add,
.fadeInPop.ng-leave {
  transform: scale(1, 1);
  animation: popOut 0.25s linear;
}
