.animate-repeat.ng-move,
.animate-repeat.ng-enter,
.animate-repeat.ng-leave {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
}
.animate-repeat.ng-leave.ng-leave-active,
.animate-repeat.ng-move,
.animate-repeat.ng-enter {
  opacity: 0;
  left: -50px;
}
.animate-repeat.ng-leave,
.animate-repeat.ng-move.ng-move-active,
.animate-repeat.ng-enter.ng-enter-active {
  opacity: 1;
  left: 0px;
}
.animated.fadeInDown.ng-leave {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.as-sortable-dragging {
  border: 1px dotted #000 !important;
}
.cancelGreen.sweet-alert button.cancel {
  background-color: #46c37b;
}
.bg-insight-grey {
  background-color: #99a4ad;
  color: white;
}
.text-insight-grey {
  color: #99a4ad;
}
.bg-insight-orange {
  background-color: #bd4a26;
  color: white;
}
.text-insight-orange {
  color: #bd4a26;
}
.badge-insight-orange {
  background-color: #bd4a26;
}
.bg-insight-blue {
  background-color: #131d28;
  color: white;
}
.text-insight-blue {
  color: #131d28;
}
.bg-insight-green {
  background-color: #006838;
}
.text-insight-green {
  color: #006838;
}
.banner .breadcrumb a {
  color: #FFF;
}
.banner .breadcrumb > .active {
  color: #AAA;
}
.btn-insight-orange {
  color: #fff;
  background-color: #bd4a26;
  border-color: #7d3119;
}
.btn-insight-orange:hover,
.btn-insight-orange:active,
.btn-insight-orange:focus {
  color: #fff;
  background-color: #7d3119;
  border-color: #bd4a26;
}
.badge.with-icon i {
  border-right: solid 1px white;
  padding-right: 5px;
  margin-right: 5px;
}
.push-150-t {
  margin-top: 150px !important;
}
.content.content-no-padding {
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
}
a[disabled="disabled"] {
  opacity: 0.5;
}
.full-width {
  width: 100%;
}
.main-container .progress-bar.active,
.main-container .progress.active .progress-bar {
  animation: none;
  -webkit-animation: none;
}
input.ng-invalid,
select.ng-invalid {
  border: solid 1px red;
}
.fc-event-container {
  cursor: pointer;
}
.required .form-material label {
  color: #d26a5c;
}
.item.item-sm {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.block.block-bordered.border-danger,
.border-danger {
  border-color: #d26a5c;
}
.border-dashed {
  border-style: dashed;
}
.strikethrough {
  text-decoration: line-through;
}
.flight-override {
  border: dashed 2px #000;
}
.flight-override:before {
  content: "\2605";
  font-size: 1.5em;
  color: #000;
}
.failed-risk {
  border: dashed 2px #f3b760;
}
.failed-risk:before {
  content: "\26A0";
  font-size: 1.5em;
  color: #f3b760;
}
.failed-compliance {
  border: dashed 2px red;
}
.failed-compliance:before {
  content: "\26A0";
  font-size: 1.5em;
  color: red;
}
.recurring-event:before {
  content: "\1f4c5";
  font-size: 1.5em;
  color: yellow;
}
/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */
.flag {
  display: inline-block;
  width: 16px;
  height: 11px;
  background: url('/img/Flags/flags.png') no-repeat;
}
.flag.flag-mx {
  background-position: -16px 0;
}
.flag.flag-ca {
  background-position: 0 0;
}
.flag.flag-us {
  background-position: 0 -11px;
}
.border-thick {
  border: 2px solid #464646;
}
.sidebar-o .sidebar-mini-show {
  opacity: 0;
  display: none;
}
.sidebar-o.sidebar-mini .sidebar-mini-show {
  opacity: 1;
  display: inline;
}
.sidebar-o.sidebar-mini :hover .sidebar-mini-show {
  opacity: 0;
  display: none;
}
.force-pointer {
  cursor: pointer;
}
.ng-hide.ng-hide-animate {
  display: none !important;
}
.st-sort-ascent:after {
  content: '\f0de';
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  margin: 0px 0px 0px 10px;
  text-decoration: none;
}
.st-sort-descent:after {
  content: '\f0dd';
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  margin: 0px 0px 0px 10px;
  text-decoration: none;
}
/**
Risk hazard table
 */
.table-square tr.square td,
.table-square tr.square th {
  width: 100px;
  height: 100px;
  border: solid 1px #bbb;
  text-align: center;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.table-square td:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
  opacity: 1;
}
.block-opt-refresh > .block-content-full {
  opacity: .15;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  canvas.chart-canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto!important;
    width: auto!important;
  }
  .no-break {
    page-break-inside: avoid;
  }
}
.impersonation-banner {
  background-color: #fc0;
  color: #000;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  top: 60px;
  width: 100%;
  z-index: 1000;
  position: absolute;
  left: 0%;
}
