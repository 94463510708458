.half-width {
  width: 48%;
  display: inline-flex;
}
/*
input.ng-invalid.ng-touched {
  background-color: #FA787E;
}*/
.padding {
  padding: 10px;
}
.development-warning {
  display: none;
  color: red;
}
.demo {
  display: none;
  color: blue;
}
body.development-environment .development-warning {
  display: block;
}
body.demo-environment .demo-warning {
  display: block;
}
