.form-material.oversize > .form-control:focus + label,
.form-material.oversize.floating.open > label {
  font-size: 24px;
  font-weight: 600;
  cursor: default;
  transform: translateY(-38px);
}
.form-material.oversize.floating > label {
  font-size: 24px;
  font-weight: 400;
  cursor: text;
  z-index: 10;
  transition: all 0.15s ease-out;
  transform: translateY(0);
}
.oversize {
  font-size: 24px;
}
.oversize input {
  font-size: 24px;
}
