li button.layer-active {
  color: #fff;
  background-color: #70b9eb;
  opacity: 1;
}
li button.layer-toggle:hover,
li button.layer-toggle:active,
li button.layer-toggle:focus {
  color: #e8e8e8;
}
li button.layer-active:hover,
li button.layer-active:active,
li button.layer-active:focus {
  background-color: #6bb0e0;
}
