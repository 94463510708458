.jumbotron {
  text-align: center;
}
.bg-darken {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(62, 74, 89, 0.83);
}
.bg-darken-orange {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(189, 74, 38, 0.91);
}
.block-highlight {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #ffffff 50%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #ffffff 50%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #ffffff 50%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 1s ease;
}
table:hover .block-highlight {
  background-position: left bottom;
}
.slider-block table {
  height: 85px;
}
/*.slider-block table .block-highlight {
  height:85px;
}*/
.highlight {
  position: relative;
  z-index: 100;
  transition: all 1s ease;
}
table:hover .highlight {
  color: #FFF;
}
.border-pop {
  border: solid 3px transparent;
  transition: all 1s linear;
}
.border-pop:hover {
  border: solid 3px black;
}
.bg-image.banner {
  height: 300px;
}
.fadeInSlow {
  animation-name: fadeIn;
  animation-delay: 1s;
  animation-duration: 2s;
}
