#legend {
  font-family: Arial, sans-serif;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  position: absolute;
  bottom: 0px;
  right: calc(-276%);
  left: 20px;
  z-index: 1000;
  font-size: 0.7em;
  width: 160px;
  border: 1px solid #333;
  color: #fff;
}
#legend h3 {
  margin-top: 0;
  font-size: 1.3em;
  padding-bottom: 10px;
}
#legend img {
  vertical-align: middle;
  width: 2em;
}
.map {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 600px;
  top: 0;
  right: 0;
}
.map-container {
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  min-height: 600px;
}
.marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.legendItem {
  display: block;
  vertical-align: middle;
}
.legendIcon {
  width: 20px;
  height: 20px;
  display: inline-block;
  -webkit-mask-image: url('../img/icons/circle.svg');
  mask-image: url('../img/icons/circle.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
.legendItem .title {
  line-height: 20px;
  display: inline-block;
}
.scheduled {
  background-color: #6795F8;
}
.completed {
  background-color: #00E85D;
}
.requested {
  background-color: #ffb8b8;
}
.cancelled {
  background-color: #f3b760;
}
.declined {
  background-color: #777276;
}
.conflict {
  background-color: #ff0000;
}
#legend .legendItem .aircraft {
  height: 20px;
  width: 20px;
}
.map-alert {
  background-image: url('../img/icons/alert.png');
  -webkit-background-image: url('../img/icons/alert.png');
  -webkit-mask-image: none;
  mask-image: none;
  background-repeat: no-repeat;
  background-size: contain;
}
.marker.uav.fixedwing {
  -webkit-mask-image: url(../img/icons/uav_fixedwing.svg);
  mask-image: url(../img/icons/uav_fixedwing.svg);
  mask-size: 90%;
  -webkit-mask-size: 90%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: currentColor;
}
.marker.uav.multicopter {
  -webkit-mask-image: url(../img/icons/uav_multicopter.svg);
  mask-image: url(../img/icons/uav_multicopter.svg);
  mask-size: 90%;
  -webkit-mask-size: 90%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: currentColor;
}
.marker.manned.fixedwing {
  -webkit-mask-image: url('../img/icons/manned_fixedwing.svg');
  mask-image: url('../img/icons/manned_fixedwing.svg');
  mask-size: 75%;
  -webkit-mask-size: 75%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: currentColor;
}
.marker.manned.helicopter {
  -webkit-mask-image: url('../img/icons/manned_helicopter.svg');
  mask-image: url('../img/icons/manned_helicopter.svg');
  mask-size: 90%;
  -webkit-mask-size: 90%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: currentColor;
}
.marker.helipad {
  -webkit-mask-image: url('../img/icons/helipad.svg');
  mask-image: url('../img/icons/helipad.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: currentColor;
}
.marker.airport {
  -webkit-mask-image: url('../img/icons/airport.svg');
  mask-image: url('../img/icons/airport.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: currentColor;
}
.marker.alert {
  -webkit-mask-image: url('../img/icons/alert.svg');
  mask-image: url('../img/icons/alert.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: currentColor;
}
.marker.alert {
  -webkit-mask-image: url('../img/icons/alert.svg');
  mask-image: url('../img/icons/alert.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: currentColor;
}
.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
.legendTab {
  padding: 0 10px 0 10px;
  text-align: center;
}
.legendTab.active {
  border-bottom: 1px solid #FFF;
}
.legendTabContent {
  margin-top: 10px;
}
