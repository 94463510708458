.dropdown-region {
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 1;
}
.dropdown-region li {
  padding: 10px;
  cursor: pointer;
}
.dropdown-region li:hover {
  background-color: #f0f0f0;
}
.selected-badges {
  margin-top: 10px;
}
.remove-badge {
  margin-left: 10px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}
.align-options {
  display: flex;
  flex-direction: column;
}
.margin5 {
  margin: 5px;
}
