.menuitem {
  margin-bottom: 5px;
}
[ng-click],
[data-ng-click],
[data-ui-sref],
[ui-sref],
[x-ng-click] {
  cursor: pointer;
}
