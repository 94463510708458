.content img.content-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: .8;
  background-position: center center;
  background-size: cover;
}
.position-relative {
  position: relative;
}
